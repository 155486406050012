
import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  legacy: false,
  globalInjection: true,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  numberFormats: {
    'de': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
    'en': {
      currency: {
        style: 'currency',
        currency: 'USD'
      }
    }
  },
  datetimeFormats : {
    'en': {
      date: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      time: {
        hour: '2-digit', minute: '2-digit'
      },
      datetime: {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: '2-digit', minute: '2-digit'
      },
      datetimehuman: {
        year: 'numeric', month: 'long', day: 'numeric',
        weekday: 'long', hour: '2-digit', minute: '2-digit'
      },
      precise: {
        hour: '2-digit', minute: '2-digit', second: '2-digit', millisecond: '2-digit'
      }
    },
    'de': {
      date: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      time: {
        hour: '2-digit', minute: '2-digit'
      },
      datetime: {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: '2-digit', minute: '2-digit'
      },
      datetimehuman: {
        year: 'numeric', month: 'long', day: 'numeric',
        weekday: 'long', hour: '2-digit', minute: '2-digit'
      },
      precise: {
        hour: '2-digit', minute: '2-digit', second: '2-digit', millisecond: '2-digit'
      }
    },
  }
})
