let websocket = null;
let isConnected = false;
let isAuthenticated	= false;
let wsKeepalive = null;
let topics = {};

export function connectWebSocket(wsBus, url) {
	websocket = new WebSocket(url);
	wsBus.emit('init');

	function setupWebSocket(websocket) {
		websocket.onopen = () => {
			isConnected = true;
			isAuthenticated = false;
			wsBus.emit('connected');
		};

		websocket.onclose = () => {
			isConnected = false;
			isAuthenticated = false;
			wsBus.emit('disconnected');
		};

		websocket.onmessage = (event) => {
			const data = JSON.parse(event.data);
			if(data.event === 'authenticated') {
				isAuthenticated = true;
				console.log('[WS] Authenticated');
			} else if(data.event === 'subscription') {
				let topic = data.topic;
				if(!(topic in topics)) {
					topics[topic] = {
						confirmed: false,
						handlers: []
					};
				}
				topics[topic].confirmed = true;
				console.log(`[WS] {topic=${topic}} subscription confirmed`);
			} else if(data.topic) {
				let topic = data.topic;
				if(topic in topics) {
					topics[topic].handlers.forEach((handler) => {
						handler(data);
					});
				}
			} else {
				wsBus.emit('data', data);
			}
		};
	}
	setupWebSocket(websocket);

	wsBus.on('reconnect', () => {
		websocket = new WebSocket(url);
		setupWebSocket(websocket);
	});

	wsBus.on('wsk', (wsk) => {
		if(isConnected) websocket.send(wsk);
		else {
			// Defer wsk exchange until ws is connected, dirty but works
			setTimeout(() => {
				wsBus.emit('wsk', wsk);
			}, 100);
		}
	});

	wsBus.on('disconnect', () => {
		websocket.close();
	});

	wsBus.on('subscribe', (data) => {
		if(!isAuthenticated) {
			setTimeout(() => {
				wsBus.emit('subscribe', data);
			}, 500);
			return;
		}
		let topic = data.topic;
		let handler = data.handler;
		console.log(`[WS] {topic=${topic}} subscribing handler ${handler}`);
		if(!(topic in topics)) {
			topics[topic] = {
				confirmed: false,
				handlers: []
			};
		}
		topics[topic].handlers.push(handler);
		if(!topics[topic].confirmed) {
			if(!data.noPublish) {
				websocket.send(JSON.stringify({event: 'subscribe', topic: topic}));
				console.log(`[WS] {topic=${topic}} requesting subscription...`);
			}
		}
	});

	wsBus.on('unsubscribe', (data) => {
		let topic = data.topic;
		let handler = data.handler;
		console.log(`[WS] {topic=${topic}} unsubscribing handler ${handler}`);
		if(!(topic in topics)) {
			topics[topic] = {
				confirmed: false,
				handlers: []
			};
		}
		topics[topic].handlers.splice(topics[topic].handlers.indexOf(handler));
		if(!topics[topic].handlers.length) {
			websocket.send(JSON.stringify({event: 'unsubscribe', topic: topic}));
			console.log(`[WS] {topic=${topic}} requesting subscription removal...`);
		}
	});

	wsBus.on('raw', (data) => {
		websocket.send(JSON.stringify(data));
	});

	wsBus.on('shell-init', () => {
		websocket.send(JSON.stringify({event: 'shell-init'}));
	});

	wsBus.on('wait-until-ready', async () => {
		while(!isAuthenticated) {
			await new Promise(resolve => setTimeout(resolve, 100));
		}
	});
}