import { defineStore } from "pinia";
import {apiGet} from "@/methods";

export const useLoopbackStore = defineStore("loopback", {
	state: () => ({
		auth: '',
		id: '',
		persona: '',
		// UI Params
		organization: null,
		organizations: [],

		workspace: null,
		workspaces: []
	}),
	actions: {
		setAuth(auth) {
			this.auth = auth;
		},
		setId(id) {
			this.id = id;
		},
		setPersona(persona) {
			this.persona = persona;
		},
		logOut() {
			this.auth = 'false';
			this.id = '';
			this.persona = '';
		},
		async ensureOrgs() {
			if(!this.organizations.length) {
				let apiCall = await apiGet(`v1/organizations`, {autoErrorHandler: false});
				if(apiCall.result) {
					this.organizations = apiCall.response.organizations;
				}
			}
		},
		async ensureWorkspaces(projectId) {
			if(!this.workspaces.length) {
				let apiCall = await apiGet(`v1/organization/${this.organization.id}/workspaces`, {autoErrorHandler: false});
				if(apiCall.result) {
					this.workspaces = apiCall.response.workspaces;
				}
			}
		},
		async routeOrganization(organizationId) {
			await this.ensureOrgs();

			let target = null;
			this.organizations.forEach((e) => {
				if(e.id === organizationId) {
					target = e;
				}
			});
			this.organization = target;
			if(this.organization !== null) await this.ensureWorkspaces(organizationId);
		},
		async routeWorkspace(workspaceId) {
			await this.ensureOrgs();

			let target = null;
			this.workspaces.forEach((e) => {
				if(e.id === workspaceId) {
					target = e;
				}
			});
			this.workspace = target;
		}
	}
});