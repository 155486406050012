import {useLoopbackStore} from "@/state/pinia";
import {apiGet} from "@/methods";



export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Select Organization", authRequired: true },
    component: () => import("../views/_app/org-select")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Sign In",
      beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if(loopback.auth === 'true' && loopback.id && loopback.persona) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/500",
    name: "500",
    component: () => import("../views/_app/unavailable.vue"),
    meta: {
      title: "Site Currently Unavailable",
      beforeResolve(routeTo, routeFrom, next) {
        next();
      }
    }
  },
  {
    path: "/authenticate",
    name: "authenticate",
    component: () => import("../views/account/login"),
    meta: {
      title: "Authenticate",
      beforeResolve(routeTo, routeFrom, next) {
        next();
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
         next();
      }
    }
  },

  // GENERIC OPERATIONS
  {
    path: "/organizations/new",
    name: "organization_create",
    meta: {
      title: "Create Organization",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        next();
      }
    },
    component: () => import("../views/organizations/create")
  },

  // VIEW TYPE: ORGANIZATION
  {
    path: "/organization/:organizationId",
    name: "organization",
    meta: {
      title: "Organization",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/view")
  },

  /**** SETTINGS ****/
  {
    path: "/organization/:organizationId/settings",
    name: "organization_settings",
    meta: {
      title: "Settings",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_settings/view")
  },
  /**** Workspaces ****/
  {
    path: "/organization/:organizationId/workspaces",
    name: "organization_workspaces",
    meta: {
      title: "Workspaces",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_workspaces/list")
  },
  {
    path: "/organization/:organizationId/workspaces/new",
    name: "organization_workspace_create",
    meta: {
      title: "Create Workspace",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_workspaces/create")
  },
  {
    path: "/organization/:organizationId/workspace/:workspaceId",
    name: "organization_workspace",
    meta: {
      title: "Workspace",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        let workspaceId = routeTo.params.workspaceId;
        await loopback.routeWorkspace(workspaceId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        if(!loopback.workspace) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}/workspace/${routeTo.params.workspaceId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.workspace = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_workspaces/view")
  },
  {
    path: "/organization/:organizationId/workspace/:workspaceId/hosts/new",
    name: "organization_workspace_host_create",
    meta: {
      title: "Create Host",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        let workspaceId = routeTo.params.workspaceId;
        await loopback.routeWorkspace(workspaceId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        if(!loopback.workspace) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}/workspace/${routeTo.params.workspaceId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.workspace = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_workspaces/_pages/host_create.vue")
  },
  /**** HOSTS ****/
  {
    path: "/organization/:organizationId/hosts",
    name: "organization_hosts",
    meta: {
      title: "Hosts",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/organizations/hosts")
  },
  {
    path: "/organization/:organizationId/host/:hostId",
    name: "organization_host",
    meta: {
      title: "Host",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_host/view")
  },
  /**** NETWORKS ****/
  {
    path: "/organization/:organizationId/networks",
    name: "organization_networks",
    meta: {
      title: "Networks",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_networks/list")
  },
  {
    path: "/organization/:organizationId/networks/new",
    name: "organization_network_create",
    meta: {
      title: "Create Network",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_networks/create")
  },
  {
    path: "/organization/:organizationId/network/:networkId",
    name: "organization_network",
    meta: {
      title: "Networks",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_networks/view")
  },
  /**** DNS ****/
  {
    path: "/organization/:organizationId/dns-zones",
    name: "organization_dns_zones",
    meta: {
      title: "DNS",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_dns/list")
  },
  {
    path: "/organization/:organizationId/dns-zones/new",
    name: "organization_dns_zone_create",
    meta: {
      title: "Create DNS Zone",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_dns/create")
  },
  {
    path: "/organization/:organizationId/dns-zone/:zoneId",
    name: "organization_dns_zone",
    meta: {
      title: "DNS Zone",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_dns/view")
  },
  /**** COMPUTE PROVIDERS ****/
  /*
  {
    path: "/organization/:organizationId/compute-providers",
    name: "organization_compute_providers",
    meta: {
      title: "Compute Providers",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_compute_providers/list")
  },
   */
  {
    path: "/organization/:organizationId/compute-providers/new",
    name: "organization_compute_provider_create",
    meta: {
      title: "Create Compute Provider",
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const loopback = useLoopbackStore();

        let organizationId = routeTo.params.organizationId;
        await loopback.routeOrganization(organizationId);

        if(!loopback.organization) {
          let apiCall = await apiGet(`v1/organization/${routeTo.params.organizationId}`, {autoErrorHandler: false});
          if(apiCall.result) {
            loopback.organization = apiCall.response;
          } else {
            return next({ name: "default" });
          }
        }
        next();
      }
    },
    component: () => import("../views/_organization/_compute_providers/create")
  },
  /**** SYSTEM ****/
  {
    path: "/system/engine-templates",
    name: "engine_templates",
    meta: { title: "Engine Templates", authRequired: true },
    component: () => import('../views/_system/engine-templates/list')
  },
  {
    path: "/system/engine-template/:engineTemplateId",
    name: "engine_template_editor",
    meta: { title: "Engine Template Editor", authRequired: true },
    component: () => import('../views/_system/engine-templates/editor.vue')
  },
  {
    path: "/engine-template/:engineTemplateId",
    name: "engine_template_editor_old",
    meta: { title: "Engine Template Editor", authRequired: true },
    component: () => import('../views/_system/engine-templates/editor_old.vue')
  },
  {
    path: "/system/dashboard",
    name: "system_dashboard",
    meta: { title: "System Dashboard", authRequired: true },
    component: () => import('../views/_system/_administration/dashboard/view.vue')
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
    /*
  {
    path: "/hosts",
    name: "hosts",
    meta: { title: "Hosts", authRequired: true },
    component: () => import('../views/hosts/hosts')
  },
  {
    path: "/hosts/create",
    name: "create_host",
    meta: { title: "Create Host", authRequired: true },
    component: () => import('../views/hosts/create_host')
  },
  {
    path: "/applications",
    name: "applications",
    meta: { title: "Applications", authRequired: true },
    component: () => import('../views/applications/applications')
  },
  {
    path: "/organizations",
    name: "organizations",
    meta: { title: "Organizations", authRequired: true },
    component: () => import('../views/organizations/organizations')
  },
  {
    path: "/host",
    name: "host",
    meta: { title: "Host", authRequired: true },
    component: () => import('../views/host/index')
  },
     */

  {
    path: "/404",
    name: "404",
    meta: { title: "404", authRequired: true },
    component: () => import('../views/_app/error-pages/404.vue')
  },
  {
     path: "/:pathMatch(.*)*",
     redirect: "404",
  },
];
