<template>
	<div id="loopback-app">
    <router-view></router-view>
	</div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>
