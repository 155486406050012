import { useLayoutStore } from "./pinia/layout";
import { useNotificationStore } from "./pinia/notification";
import { useLoopbackStore } from "./pinia/loopback";

import { createPinia } from "pinia";

const pinia = createPinia();
export default pinia;

export { useLayoutStore, useNotificationStore, useLoopbackStore };
