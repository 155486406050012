import Vue from 'vue';
import i18n from '@/i18n';
import {VueApp} from "@/main.js";
import appConfig from "@/app.config";
import Swal from 'sweetalert2/dist/sweetalert2';

const debug = (process.env.NODE_ENV === 'development');

export function formatBytes(bytes) {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function formatBytesLegacy(bytes) {
	if (bytes === 0) return '0 Bytes';
	const k = 1000;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return Math.round(bytes / Math.pow(k, i)) + ' ' + sizes[i];
}

export function humanizeDuration(seconds) {
	const minute = 60;
	const hour = 3600;
	const day = 86400;
	const week = 604800;
	const month = 2629746; // Average month length in seconds
	const year = 31556952; // Average year length in seconds

	const years = Math.floor(seconds / year);
	if (years > 0) {
		return `${years} year${years !== 1 ? 's' : ''}`;
	}

	const months = Math.floor(seconds / month);
	if (months > 0) {
		return `${months} month${months !== 1 ? 's' : ''}`;
	}

	const weeks = Math.floor(seconds / week);
	if (weeks > 0) {
		return `${weeks} week${weeks !== 1 ? 's' : ''}`;
	}

	const days = Math.floor(seconds / day);
	if (days > 0) {
		return `${days} day${days !== 1 ? 's' : ''}`;
	}

	const hours = Math.floor(seconds / hour);
	if (hours > 0) {
		return `${hours} hour${hours !== 1 ? 's' : ''}`;
	}

	const minutes = Math.floor(seconds / minute);
	return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
}

export async function get_acsrf_token() {
	let response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'});
	let data = await response.json();
	return data.acsrf_token;
}

export function with_acrf_token(f) {
	return new Promise((resolve, reject) => {
		fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
		.then(response => {
			if(response.ok) {
				resolve(response.json());
			} else {
				throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
			}
		})
		.catch(() => reject)
	});
}

export function parseISO8601String(dateString) {
	var timebits = /^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2})(?::([0-9]*)(\.[0-9]*)?)?(?:([+-])([0-9]{2})([0-9]{2}))?/;
	var m = timebits.exec(dateString);
	var resultDate;
	if (m) {
		var utcdate = Date.UTC(parseInt(m[1]),
			parseInt(m[2])-1, // months are zero-offset (!)
			parseInt(m[3]),
			parseInt(m[4]), parseInt(m[5]), // hh:mm
			(m[6] && parseInt(m[6]) || 0),  // optional seconds
			(m[7] && parseFloat(m[7])*1000) || 0); // optional fraction
		// utcdate is milliseconds since the epoch
		if (m[9] && m[10]) {
			var offsetMinutes = parseInt(m[9]) * 60 + parseInt(m[10]);
			utcdate += (m[8] === '+' ? -1 : +1) * offsetMinutes * 60000;
		}
		resultDate = new Date(utcdate);
	} else {
		resultDate = null;
	}
	return resultDate;

	//return new Date(Date.parse(dateString));
}

export function startsWith(string, searchString, position) {
	position = position || 0;
	return string.indexOf(searchString, position) === position;
}


export async function apiCall(route, method, options) {
	//payload = null, credentials = 'include', retrieveACRFToken = false, autoFormatJSON = true, headers = {'Content-Type': 'application/json'}, decodeResponse = true, errorHandling = true) {
	let responseStatus;
	let responseStatusText;
	let responseUrl;

	try {
		if(options.retrieveACSRFToken) {
			let response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/csrf', {credentials: 'include'});
			let data = await response.json();
			options.payload['_security.csrf'] = data.token;
		}

		let url = new URL(process.env.VUE_APP_ROOT_API + route);

		let args = {
			method: method,
			credentials: options.credentials
		}

		if(options.payload !== null) {
			if(options.autoFormatJSON) args.body = JSON.stringify(options.payload);
			else args.body = options.payload;
		}
		if(debug) console.log(`[REQUEST] ${url} [args=${JSON.stringify(args)}; headers=${JSON.stringify(options.headers)};]`);
		let response = await fetch(url, args, {headers: options.headers});

		let result = true;
		responseStatus = response.status;
		responseStatusText = response.statusText;
		responseUrl = response.url;

		if(!response.ok) {
			result = false;
			switch(response.status) {
				case 400: {
					break;
				}
				case 401: {
					if(options.autoErrorHandler) {
						await Swal.fire({
							icon: 'error',
							text: i18n.global.t('ERROR_401')
						});
						location.reload();
					}
					break;
				}
				case 403: {
					if(options.autoErrorHandler) {
						Swal.fire({
							icon: 'error',
							text: i18n.global.t('ERROR_403')
						});
					}
					break;
				}
				case 404: {
					break;
				}
				case 429: {
					if(options.autoErrorHandler) {
						await Swal.fire({
							icon: 'warning',
							text: i18n.global.t('ERROR_429')
						});
					}
					break;
				}
				default: {
					if(options.autoErrorHandler) {
						await Swal.fire({
							icon: 'error',
							title: i18n.global.t('ERROR_TITLE_GENERIC'),
							text: `${response.statusText}`
						});
					}
					break;
				}
			}
		}
		let data;
		if(options.decodeResponse && responseStatus !== 204) {
			data = await response.json();
		} else {
			data = await response.blob();
		}

		return {
			result: result,
			status: responseStatus,
			response: data,
		};

	} catch (error) {
		console.error(`(${responseUrl}) Failed with API error ${responseStatus}=${responseStatusText} (${error})`);
		return {
			result: false,
			status: responseStatus,
			response: null,
		};
	}
}

export async function apiPost(route, payload, options = {}) {
	let defaultOptions = {
		payload: payload,
		credentials: 'include',
		retrieveACSRFToken: true,
		autoFormatJSON: true,
		headers: {'Content-Type': 'application/json'},
		decodeResponse: true,
		autoErrorHandler: true
	}
	let requestOptions = Object.assign({}, defaultOptions, options || {});
	return apiCall(route, 'POST', requestOptions);
}

export async function apiGet(route, options = {}) {
	let defaultOptions = {
		credentials: 'include',
		retrieveACSRFToken: false,
		autoFormatJSON: true,
		headers: {'Content-Type': 'application/json'},
		decodeResponse: true,
		autoErrorHandler: true
	}
	let requestOptions = Object.assign({}, defaultOptions, options || {});
	return apiCall(route, 'GET', requestOptions);
}

export async function apiPut(route, payload, options = {}) {
	let defaultOptions = {
		payload: payload,
		credentials: 'include',
		retrieveACSRFToken: true,
		autoFormatJSON: true,
		headers: {'Content-Type': 'application/json'},
		decodeResponse: true,
		autoErrorHandler: true
	}
	let requestOptions = Object.assign({}, defaultOptions, options || {});
	return apiCall(route, 'PUT', requestOptions);
}

export async function apiPatch(route, payload, options = {}) {
	let defaultOptions = {
		payload: payload,
		credentials: 'include',
		retrieveACSRFToken: true,
		autoFormatJSON: true,
		headers: {'Content-Type': 'application/json'},
		decodeResponse: true,
		autoErrorHandler: true
	}
	let requestOptions = Object.assign({}, defaultOptions, options || {});
	return apiCall(route, 'PATCH', requestOptions);
}

export async function apiDelete(route, payload, options = {}) {
	let defaultOptions = {
		payload: payload,
		credentials: 'include',
		retrieveACSRFToken: true,
		autoFormatJSON: true,
		headers: {'Content-Type': 'application/json'},
		decodeResponse: true,
		autoErrorHandler: true
	}
	let requestOptions = Object.assign({}, defaultOptions, options || {});
	return apiCall(route, 'DELETE', requestOptions);
}

export async function apiFile(route, payload, options = {}) {
	let defaultOptions = {
		payload: payload,
		credentials: 'include',
		retrieveACSRFToken: true,
		autoFormatJSON: true,
		headers: { 'Content-Type': 'multipart/form-data' },
		decodeResponse: true,
		autoErrorHandler: true
	}
	let requestOptions = Object.assign({}, defaultOptions, options || {});
	return apiCall(route, 'POST', requestOptions);
}
